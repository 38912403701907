import { Link } from 'react-router-dom';
import './PageDetail.scss';
  
function PageDetail(props) {
  return (
    <div className="PageDetail">
      {props.section.map((item, index) => 
        (
          (props.path === "partnerships")
          ?
          <div key={index} className="page-container">
            <h3>{item.title}</h3>
            <div className="partnerships-container">
              <div className="items-container">
                <p>{item.p1}</p>
                <a href="https://www.bollinger-grohmann.com/" target="_blank" rel="noreferrer"><img src={`../../assets/medias/img/${item.img1}`} alt={item.alt1}></img></a>
                <div className='item-partt'>
                  <p>{item.p2}</p>
                  <a href="https://www.nivuniconnu.org/" target="_blank" rel="noreferrer"><img src={`../../assets/medias/img/${item.img3}`} alt={item.alt3}></img></a>
                </div>
              </div>
            </div>
          </div>
          : (props.path === "our-expertise") ?
          <div key={index} className="page-container">
            <h3>{item.title}</h3>
            <div className='expertise-container'>
              <p>{item.p1}</p>
              <div className="items-container">
                <h4>{item.li1}</h4>
                <p className='text-item'>{item.p_li1}</p>
                <h4>{item.li2}</h4>
                <p className='text-item'>{item.p_li2}</p>
                <h4>{item.li3}</h4>
                <p className='text-item'>{item.p_li3}</p>
                <h4>{item.li4}</h4>
                <p className='text-item'>{item.p_li4}</p>
                <h4>{item.li5}</h4>
                <p className='text-item'>{item.p_li5}</p>
                <h4>{item.li6}</h4>
                <p className='text-item'>{item.p_li6_1}</p>
                <p className='text-item'>{item.p_li6_2}</p>
                <p className='text-item'>
                  {item.p_li6_3} 
                  <a href="mailto:hello@lignekobalt.com?subject=Demande de renseignements&body=Bonjour, serait-il possible d'obtenir des informations complémentaires sur les formations que vous proposez s'il vous plaît ?">
                    {item.a}
                  </a>
                </p>
              </div>
            </div>
          </div>
          :
          <div key={index} className="page-container">
            <h3>{item.title}</h3>
            <div className="over-values-container">
              <div className="items-container">
                <p>{item.p1}</p>
                <p>{item.p2}</p>
                <p>{item.p3}</p>
              </div>
            </div>
          </div>
        )
      )}
    </div>
  );
}

export default PageDetail;
