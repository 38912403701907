import { dataFrLateral, dataEnLateral } from '../../../data/data';

import './LateralNavBar.scss';
  
function LateralNavBar(props) {
  let data;

  if (props.language === 'fr') {
    data = dataFrLateral;
  }
  if (props.language === 'en') {
    data = dataEnLateral;
  }
  return (
    <div className="LateralNavBar">
      <div className="nav-links">
        <a className="item" href="https://www.linkedin.com/company/ligne-kobalt/?viewAsMember=true" target="_blank" rel="noreferrer">
          <img className="instagram" src="../../assets/medias/svg/contact/linkedin-icon.svg" alt="linkedin-link" />
        </a>
        <div className="text">
          <img className="suivez-nous" src={`../../assets/medias/svg/contact/${data.img}.svg`} alt="instagram-link" />
        </div>
      </div>
    </div>
  );
}

export default LateralNavBar;
