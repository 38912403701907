import { useParams } from 'react-router-dom'
import { dataFr, dataEn } from '../../../data/data';
import { route } from '../../../data/route';
import PageDetail from './PageDetail';
  
function Page(props) {
  const { value } = useParams();

  let data;
  if (props.language === 'fr') {
    data = dataFr;
  }
  if (props.language === 'en') {
    data = dataEn;
  }
  const id = route.find(section => section.href === value);
  const section = data[id.id].map(a => a);
  return (
    <div className="Page">
      <PageDetail 
        path={value} 
        section={section} 
      />
    </div>
  );
}

export default Page;
