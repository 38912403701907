import { useEffect } from 'react';
import './Start.scss';

function HomePage() {
  useEffect(() => {
    document.getElementById("lineLeftLogo").classList.add("cls-3-ani");
    setTimeout(() => {
      for (let i = 0; i < 10; i++) {
        setTimeout(() => {
          document.getElementsByClassName("cls-1")[i].style.fill = "rgba(255, 255, 255, 0.6)";
        }, 400 * i);
      }
    }, 1000);
    setTimeout(() => {
      document.getElementById("lineRightLogo").style.visibility = "visible";
      document.getElementById("lineRightLogo").classList.add("cls-3-ani");
    }, 5000);
    setTimeout(() => {
      document.getElementsByClassName("cls-3")[1].style.stroke = "#2441c6";
      document.getElementsByClassName("cls-3")[0].style.stroke = "#2441c6";
    }, 6500);
    setTimeout(() => {
      document.getElementsByClassName("cls-3")[1].style.opacity = "0";
      document.getElementsByClassName("cls-3")[0].style.opacity = "0";
      setTimeout(() => {
        for (let i = 0; i < 10; i++) {
          document.getElementsByClassName("cls-1")[i].style.fill = "rgba(255, 255, 255, 0.2)";
        }
      }, 1000);
    }, 7000);
  }, []);

  return (
    <div id="start" className="Start">
      <svg id="logo" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1920 109.40281">
        <path id="cls10" className="cls-1" d="M386.44444,592.9103V488.00323h19.48233v87.8223h56.49989V592.9103Z" transform="translate(0 -485.75538)"/>
        <path id="cls11" className="cls-1" d="M481.46153,592.9103V488.00323h19.48234V592.9103Z" transform="translate(0 -485.75538)"/>
        <path id="cls12" className="cls-1" d="M525.22114,540.75648c0-31.62172,23.82986-55.0011,54.25206-55.0011,27.72539,0,43.01287,15.2867,48.55764,31.77213l-18.88369,7.19324c-4.49568-12.43875-12.88844-22.18069-29.67393-22.18069-18.58287,0-33.8688,13.63822-33.8688,38.06669,0,24.27806,14.53613,38.0667,33.27017,38.0667,18.73252,0,28.17437-11.84009,29.22346-22.031H573.32982V539.857h54.25207V592.9103H609.59758v-16.335c-4.19637,7.04282-13.33892,18.58287-34.76976,18.58287C546.95281,595.1582,525.22114,575.07575,525.22114,540.75648Z" transform="translate(0 -485.75538)"/>
        <path id="cls13" className="cls-1" d="M654.10855,592.9103V488.00323h26.826L731.74,559.93947V488.00323h19.48233V592.9103H732.78909l-59.19824-83.77554V592.9103Z" transform="translate(0 -485.75538)"/>
        <path id="cls14" className="cls-1" d="M782.39742,592.9103V488.00323h77.03133v16.78545h-57.5491v25.77693h43.16252V547.051H801.87965v29.0738h57.549V592.9103Z" transform="translate(0 -485.75538)"/>
        <path id="cls15" className="cls-1" d="M1020.84028,540.60682c0-31.77213,21.88133-54.85143,54.70255-54.85143,32.97093,0,54.55138,23.0793,54.55138,54.85143,0,32.07144-21.43085,54.55138-54.55138,54.55138C1042.27112,595.1582,1020.84028,572.67826,1020.84028,540.60682Zm88.72253,0c0-22.031-12.58913-38.21635-34.02-38.21635-21.582,0-34.16962,16.18538-34.16962,38.21635,0,21.731,12.5876,37.91705,34.16962,37.91705C1096.97368,578.52386,1109.56281,562.33773,1109.56281,540.60682Z" transform="translate(0 -485.75538)"/>
        <path id="cls16" className="cls-1" d="M1154.37455,592.9103V488.00323H1202.781c22.78077,0,34.16963,10.34128,34.16963,25.47761,0,13.03809-8.39275,21.43083-20.2321,24.57812,13.48858,2.39825,23.68021,12.1394,23.68021,25.77686,0,18.73331-16.18689,29.07458-36.868,29.07458Zm19.48231-62.49442h26.22736c10.49094,0,17.08481-4.346,17.08481-13.1885s-6.59387-12.43872-17.08481-12.43872h-26.22736Zm0,45.709h26.826c11.54,0,19.33265-4.795,19.33265-14.68656s-7.79262-14.68731-19.33265-14.68731h-26.826Z" transform="translate(0 -485.75538)"/>
        <path id="cls17" className="cls-1" d="M1334.81724,592.9103l-8.99137-25.9273h-44.06043l-9.14254,25.9273h-20.982l39.86567-104.90707h25.77688l39.26544,104.90707Zm-47.05807-42.712h32.22109l-8.69206-25.32791c-4.04672-11.83934-5.84408-16.9344-7.34366-21.13078-1.34839,4.046-3.14728,9.5915-7.34365,21.28119Z" transform="translate(0 -485.75538)"/>
        <path id="cls18" className="cls-1" d="M1375.58378,592.9103V488.00323h19.48231v87.8223H1451.566V592.9103Z" transform="translate(0 -485.75538)"/>
        <path id="cls19" className="cls-1" d="M1477.94454,592.9103V504.78868h-36.4189V488.00323h92.9188v16.78545h-37.0176V592.9103Z" transform="translate(0 -485.75538)"/>
        <polygon className="cls-2" points="976.334 53.201 1013.113 2.011 989.244 2.011 959.671 44.494 944.81 44.494 944.81 2.011 925.295 2.011 925.295 107.093 944.81 107.093 944.81 61.157 959.071 61.157 992.847 107.393 1017.316 107.393 976.334 53.201"/>
        <line id="lineRightLogo" className="cls-3" x1="1552.59045" y1="10.66392" x2="1920" y2="10.66389"/>
        <line id="lineLeftLogo" className="cls-3" y1="10.66398" x2="368.29844" y2="10.66394"/>
      </svg>
    </div>
  );
}

export default HomePage;
