import React, { useState, useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';
import Start from './common/components/start/Start';

import Navbar from './common/components/nav-bar/Navbar';
import LateralNavbar from './common/components/nav-bar/LateralNavBar';

import HomePage from './common/components/home-page/HomePage';
import Page from './common/components/pages/Page';
import Footer from './common/components/footer/Footer';

import './App.scss';
import InactivityScreenSaver from './common/components/inactivity/InactivityScreenSaver';

function App() {
  const [isLoading, setIsLoading] = useState(true);
  const [language, setLanguage] = useState('fr');
  

  function handleLanguage() {
    if (language === 'fr') {
      setLanguage('en');
    }
    else {
      setLanguage('fr');
    }
  }

  useEffect(() => {
    // Simulation d'une tâche asynchrone
    setTimeout(() => {
      setIsLoading(false);
    }, 8000); // Ici, le chargement est simulé pendant 3 secondes. Vous pouvez ajuster cette valeur selon vos besoins.
  }, []);

  if (isLoading && window.location.pathname === "/") {
    return (
      <Start />
    );
  }

  // Rendu du contenu uniquement après le chargement
  return (
    <div className="App">
      <InactivityScreenSaver language={language} />
      <Navbar language={language} handleLanguage={handleLanguage} />
      <LateralNavbar language={language} />
      <Routes>
        <Route exact path="/" element={<HomePage language={language} />} />
        <Route exact path="/page/:value" element={<Page language={language} handleLanguage={handleLanguage} />} />
      </Routes>
      <Footer language={language} />

    </div>
  );
}

export default App;
