import React, { useState } from 'react';
import Menu from './Menu';
import { Link } from 'react-router-dom';
import './Navbar.scss';
  
function Navbar(props) {
  const handleMouseEnter = () => {
    for (let i = 0; i < document.getElementsByClassName("cls-1").length; i++) {
      document.getElementsByClassName("cls-1")[i].style.fill = "rgba(255, 255, 255, 0.6)";
    }
    for (let i = 0; i < document.getElementsByClassName("cls-1-1").length; i++) {
      document.getElementsByClassName("cls-1-1")[i].style.stroke = "#2441c6";
    }
  };

  const handleMouseLeave = () => {
    for (let i = 0; i < document.getElementsByClassName("cls-1").length; i++) {
      document.getElementsByClassName("cls-1")[i].style.fill = "rgba(255, 255, 255, 0.2)"; // 3c3c3c
    }
    for (let i = 0; i < document.getElementsByClassName("cls-1-1").length; i++) {
      document.getElementsByClassName("cls-1-1")[i].style.stroke = "rgba(255, 255, 255, 0.15)";
    }
  };
  function handleMenu() {
    setMenuIsOpen(!menuIsOpen)
    document.getElementById("burger").classList.toggle('active');
  }

  const [menuIsOpen, setMenuIsOpen] = useState(false);
  let bg = "bg";
  if (menuIsOpen) {
    bg += " open";
  }
  return (
    <div className="Navbar">
      <Link to="/">
        <img className="icon-logo" src="../../assets/medias/svg/k.svg" alt="icon-logo" />
      </Link>
      <div className="nav-items">
        <div 
          className="call-us"
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <a className="text-call-us" href="mailto:hello@lignekobalt.com">
            <div className="plus">
              <span className="bar"></span>
              <span className="bar"></span>
            </div>
            Écrire
          </a>
        </div>

        <button onClick={props.handleLanguage} className="lang">{props.language}</button>

        <button onClick={handleMenu} className="menu-bar-container">
          <p className="text-menu-bar">menu</p>
          <svg
            id="burger"
            width="30"
            height="30"
            viewBox="0 0 32 42"
            xmlns="http://www.w3.org/2000/svg"
            stroke='white'
          >
            <g transform="matrix(1,0,0,1,-389.5,-264.004)">
              <g id="arrow_left2">
                <g transform="matrix(1,0,0,1,0,5)">
                  <path
                    id="top"
                    d="M390,270L420,270L420,270C420,270 420.195,250.19 405,265C389.805,279.81 390,279.967 390,279.967"
                  />
                </g>
                <g
                  transform="matrix(1,1.22465e-16,1.22465e-16,-1,0.00024296,564.935)"
                >
                  <path
                    id="bottom"
                    d="M390,270L420,270L420,270C420,270 420.195,250.19 405,265C389.805,279.81 390,279.967 390,279.967"
                  />
                </g>
                <path id="middle" d="M390,284.967L420,284.967" />
              </g>
            </g>
          </svg>

        </button>
        
      </div>
      <Menu menu={menuIsOpen} setMenu={setMenuIsOpen} handleMenu={handleMenu} handleLanguage={props.handleLanguage} language={props.language} />
      <div id="bg" className={bg}></div>

    </div>
  );
}

export default Navbar;
