import { Link } from 'react-router-dom';
import { dataFrMenu, dataEnMenu } from '../../../data/data';
import './Menu.scss';
  
function Menu(props) {

  let menu = "Menu";
  if (props.menu) {
    menu += " open";
  }

  let data;
  if (props.language === 'fr') {
    data = dataFrMenu;
  }
  if (props.language === 'en') {
    data = dataEnMenu;
  }
  return (
    <div className={menu}>
      <div className="link-container">
      <Link className="link-item" to="/" onClick={() => props.handleMenu()}>{data.i1}</Link>
        <Link className="link-item" to="page/about-us" onClick={() => props.handleMenu()}>{data.i2}</Link>
        <Link className="link-item" to="page/our-expertise" onClick={() => props.handleMenu()}>{data.i3}</Link>
        <Link className="link-item" to="page/our-values" onClick={() => props.handleMenu()}>{data.i4}</Link>
        <Link className="link-item" to="page/partnerships" onClick={() => props.handleMenu()}>{data.i5}</Link>
        <hr></hr>
        <a className="link-item contact" href="mailto:hello@lignekobalt.com">hello@lignekobalt.com</a>
        <a className="link-item contact" href="https://www.linkedin.com/company/ligne-kobalt/?viewAsMember=true">LinkedIn</a>
      </div>
    </div>
  );
}

export default Menu;
