import { dataFrFooter, dataEnFooter } from '../../../data/data';
import './Footer.scss';

function Footer(props) {
  let data;
  if (props.language === 'fr') {
    data = dataFrFooter;
  }
  if (props.language === 'en') {
    data = dataEnFooter;
  }
  return (
    <div className="Footer">
      <a target="_blank" rel="noopener noreferrer" href="../../../assets/medias/doc/mentions-legales.pdf">{data.a}</a>
    </div>
  );
}

export default Footer;
