export const dataFr = 
[
  [
    {
      title: "Qui sommes-nous ?",
      p1: "Ligne Kobalt est bien plus qu'une agence de communication ordinaire. Nous sommes des passionnés, animés par la volonté de créer des connexions vibrantes entre les marques, les entreprises, les individus, et tous ceux qui croisent leur chemin, qu'ils soient réels ou virtuels.",
      p2: "Nous sommes fiers de notre équipe composée d'experts chevronnés, comprenant des photographes, des graphistes, des directeurs.trices de communication, des directeurs.trices de marketing, des web designers et des designers UI/UX. Chacun.e d'entre eux possède une expérience significative et apporte sa touche personnelle pour créer une symphonie visuelle qui vous captivera.",
      p3: "Chez Ligne Kobalt, nous jouons le rôle de chef d'orchestre à vos côtés, travaillant en étroite collaboration avec ces experts talentueux pour répondre au mieux à vos besoins. Nous croyons en l'importance d'une collaboration étroite pour créer une expérience inoubliable qui dépasse vos attentes."
    },
  ],
  [
    {
      title: "Nos valeurs",
      p1: "Chez Ligne Kobalt, nous comprenons que la communication est un domaine vaste et complexe, englobant de nombreux aspects. Notre objectif est de vous accompagner de manière authentique pour créer une communication qui reflète vos valeurs, atteindre votre public cible, développer de nouvelles idées et faire briller votre marque.",
      p2: "Nous nous inspirons de l'univers fascinant de la soft culture, où l'architecture, le design, l'art et même le sport se rencontrent pour former une harmonie captivante. C'est dans cet environnement stimulant que nous puisons notre créativité afin de vous offrir des solutions de communication uniques et impactantes.",
      p3: "Notre engagement est de vous offrir une expérience de communication exceptionnelle en fusionnant notre expertise avec votre vision et vous propulser vers de nouveaux horizons."
    },
  ],
  [
    {
      title: "Nos expertises",
      p1: "Chez Ligne Kobalt, nous mettons tout en œuvre pour vous offrir une communication efficace et immersive qui vous démarquera. Nous créons une stratégie de communication complète et envoûtante :",
      li1: "Identité de marque :",
      p_li1: "Nous concevons des logos, des palettes de couleurs et des typographies uniques pour une identité visuelle cohérente. De plus, nous développons votre site internet pour renforcer votre présence en ligne.",
      li2: "Gestion de la réputation :",
      p_li2: "Nous veillons à maintenir une image positive, à instaurer la confiance et à satisfaire vos clients. Notre expertise en gestion de réputation vous permet de préserver une réputation solide et d'entretenir des relations positives avec votre public.",
      li3: "Stratégie éditoriale et planning rédactionnel :",
      p_li3: "Nous effectuons une analyse approfondie de votre communication actuelle et travaillons ensemble pour définir les principaux axes à développer pour l'avenir, en tenant compte des ambitions de votre entreprise ainsi que des moments clés à venir. Pour faciliter le suivi, nous mettons en place un tableau collaboratif sous forme de tableau de bord, qui vous permettra de visualiser aisément les publications et actions prévues sur les différentes plateformes en ligne et hors ligne.",
      li4: "Community management :",
      p_li4: "Nous animons et engageons activement votre communauté en ligne. Nous favorisons des interactions positives, répondons aux questions et commentaires, et créons du contenu captivant pour renforcer vos liens avec votre audience.",
      li5: "Relations publiques & événementiel :",
      p_li5: "Nous gérons vos relations avec les médias, les journalistes, les influenceurs et le grand public. Nous diffusons efficacement vos messages clés, organisons des événements pertinents, rédigeons des communiqués de presse percutants et gérons les interviews médiatiques pour accroître votre visibilité.",
      li6: "Formation en communication : ",
      p_li6_1: "Que diriez-vous de prendre les rênes de votre propre transformation digitale ? Ligne Kobalt est là pour vous accompagner !",
      p_li6_2: "Nous sommes à vos côtés pour vous aider à enrichir votre parcours et à maîtriser votre communication. En plus d'un accompagnement en physique ou en visio, vous disposez d'un accès illimité à nos E-learnings.",
      p_li6_3: "Nos offres de formation sont possibles via l'OPCO, le CPF ou un financement personnel et sont certifiées Qualiopi.",
      a: "Je souhaite plus d'informations !"
    }
  ],
  [
    {
      title: "Partenaires de confiance",
      p1: "L'agence Ligne Kobalt est intégrée à la direction de la communication globale du groupe d'ingénierie  Bollinger+Grohmann fondée en 1983 et est la référente privilégiée pour la France, la Belgique et la Norvège.",
      img1: "bollinger.jpeg",
      alt1: "Logo Bollinger+Grohmann",
      img2: "bollinger_instagram.jpg",
      alt2: "Logo Bollinger+Grohmann Instagram",
      p2: "Ligne Kobalt fait également partie de NiVuNiConnu, réseau dédié aux professionnels de la communication et du développement en architecture, urbanisme, paysage et ingénierie, créé en 2020 et basé en France.",
      img3: "NVNC.jpg",
      alt3: "Logo NiVuNiConnu"
  
    }
  ]
]

export const dataEn = 
[
  [
    {
      title: "Overview",
      p1: "Ligne Kobalt is much more than a typical communication office. We are enthusiasts driven by the desire to create vibrant connections between brands, businesses, individuals, and all those who come across their path, whether real or virtual.",
      p2: "We take pride in our team of seasoned experts, including photographers, graphic designers, communication directors, marketing directors, web designers, and UI/UX designers. Each of them brings significant experience and adds their personal touch to create a visual symphony that will captivate you.",
      p3: "At Ligne Kobalt, we play the role of a conductor by your side, working closely with these talented experts to best meet your needs. We believe in the importance of close collaboration to create an unforgettable experience that surpasses your expectations."
    },
  ],
  [
    {
      title: "Our values",
      p1: "At Ligne Kobalt, we understand that communication is a vast and intricate field encompassing numerous facets. Our goal is to authentically guide you in creating communication that reflects your values, reaches your target audience, cultivates new ideas, and elevates your brand.",
      p2: "We draw inspiration from the captivating realm of soft culture, where architecture, design, art, and even sports intersect to form an exquisite harmony. Within this stimulating environment, we harness our creativity to provide you with unique and impactful communication solutions.",
      p3: "Our commitment is to offer you an exceptional communication experience by merging our expertise with your vision and propelling you towards new horizons."
    },
  ],
  [
    {
      title: "Our expertise",
      p1: "At Ligne Kobalt, we go to great lengths to provide effective and immersive communication that sets you apart. We craft a comprehensive and captivating communication strategy:",
      li1: "Brand Identity:",
      p_li1: "We design logos, unique colour palettes, and typography for a consistent visual identity. Furthermore, we develop your website to bolster your online presence.",
      li2: "Reputation Management:",
      p_li2: "We maintain a positive image, build trust, and satisfy your clients. Our expertise in reputation management enables you to uphold a strong reputation and nurture positive relationships with your audience.",
      li3: "Editorial Strategy and Content Planning:",
      p_li3: "We thoroughly analyse your current communication and collaborate to define key areas for future development, considering your company's ambitions and upcoming milestones. We implement a collaborative dashboard-style tool to facilitate tracking, allowing you to easily visualise scheduled posts and actions across various online and offline platforms.",
      li4: "Community Management:",
      p_li4: "We actively engage and animate your online community. We foster positive interactions, respond to inquiries and comments, and create compelling content to strengthen connections with your audience.",
      li5: "Public Relations & Events:",
      p_li5: "We oversee your relationships with the media, journalists, influencers, and the general public. We effectively disseminate your key messages, organise relevant events, draft impactful press releases, and manage media interviews to enhance your visibility.",
      li6: "Communication training : ",
      p_li6_1: "How would you like to take the reins of your own digital transformation? Ligne Kobalt is here to support you!",
      p_li6_2: "We're at your side to help you enrich your career path and master your communications. In addition to face-to-face or online support, you have unlimited access to our e-learnings.",
      p_li6_3: "Our training courses are available via the OPCO, the CPF or personal funding, and are Qualiopi certified.",
      a: " I would like more information!"
    }
  ],
  [
    {
      title: "Partnerships",
      p1: "Ligne Kobalt is part of the global communication department of the engineering group Bollinger+Grohmann, founded in 1983, and serves as the primary point of reference for France, Belgium, and Norway.",
      img1: "bollinger.jpeg",
      alt1: "Logo Bollinger+Grohmann",
      img2: "bollinger_instagram.jpg",
      alt2: "Logo Bollinger+Grohmann Instagram",
      p2: "Ligne Kobalt is also a part of the association NVNC, dedicated to professionals in communication and development within architecture, urban planning, landscape, and engineering, established in 2020 and based in France.",
      img3: "NVNC.jpg",
      alt3: "Logo NiVuNiConnu"
    }
  ]
]

export const dataFrMenu = 
{
  i1: "Accueil",
  i2: "Qui sommes-nous ?",
  i3: "Nos expertises",
  i4: "Nos valeurs",
  i5: "Partenaires de confiance"
}

export const dataEnMenu = 
{
  i1: "Home",
  i2: "Overview",
  i3: "Our expertise",
  i4: "Our values",
  i5: "Partnerships"

}

export const dataFrHomePage = 
{
  p: "La communication au coeur de vos organisations",
  p1: "agence de communication"
}

export const dataEnHomePage = 
{
  p: "Communication at the heart of your organisations",
  p1: "communication agency"
}

export const dataFrLateral = 
{
  img: "follow-us"
}

export const dataEnLateral = 
{
  img: "follow-us-en"
}

export const dataFrFooter = 
{
  a: "Mentions légales"
}

export const dataEnFooter = 
{
  a: "Legal notices"
}

export const dataFrInactivity = 
{
  p: "Écran en veille."
}

export const dataEnInactivity = 
{
  p: "Screen in sleep mode."
}